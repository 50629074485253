import React, {useState} from 'react';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from '../components/Navbar';
import DecentralCard1 from '../assets/nightsable-assets/images/cards/small_landing_page1.jpg'
import DecentralCard2 from '../assets/nightsable-assets/images/cards/small_landing_page2.jpg'
import YummyFoodCard from '../assets/nightsable-assets/images/cards/small_landing_page3.jpg'
import MomentumVendingCard1 from '../assets/nightsable-assets/images/cards/small_landing_page4.jpg'
import MomentumVendingCard2 from '../assets/nightsable-assets/images/cards/small_landing_page5.jpg'
import StylusCard from '../assets/nightsable-assets/images/cards/small_landing_page6.jpg'





const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 2,
  autoplay: true,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1215,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};



export default function LandingPage() {

  let [navbarExpand, setNavbarExpand] = useState(false);

  return (
    <div >
          
        <section id="section-header" className="relative overflow-hidden">
          <Navbar navbarExpand={navbarExpand} setNavbarExpand={setNavbarExpand}/>
          <div className={`relative pt-20 pb-24 lg:pb-40`}>
            <div className='container relative z-10 px-4 mx-auto'>
              <div className='flex flex-wrap items-end -m-8'>
                <div className='relative z-10 w-full p-8 md:w-1/2 lg:w-7/12'>
                  <div className='md:max-w-xl'>
                    <h1 className='mb-8 tracking-tighter text-white text-7xl lg:text-8xl xl:text-10xl'>
                      Make Your Business Stand Out With SWA
                    </h1>
                    <p className='mb-10 text-lg text-white md:max-w-xs'>
                      Get your business online today! <br/>We handle all of the fraustrating startup challenges so you don't have to.
                    </p>
                    <Link
                      className='inline-block px-8 py-4 tracking-tighter transition duration-300 rounded-full bg-lightTeal text-darkTeal hover:bg-darkTeal hover:text-lightTeal focus:ring-4 focus:ring-lightTeal focus:ring-opacity-40'
                      to='/consultation'
                    >
                      Book Consultation
                    </Link>
                  </div>
                </div>
                <div className='w-full p-8 md:w-1/2 lg:w-5/12'>
                  <div className='relative mx-auto max-w-max md:mr-0'>
                    <img
                      src='nightsable-assets/images/headers/swa_illu.svg'
                      alt=''
                      
                    />
                    <img
                      className='absolute -top-16 -left-16'
                      src='nightsable-assets/images/headers/swa_star.svg'
                      alt=''
                    />
                    <img
                      className='absolute top-56 -left-44'
                      src='nightsable-assets/images/headers/swa_star2.svg'
                      alt=''
                    />
                    <div className='absolute bottom-10 -right-40'>
                      <img
                        className='relative -bottom-4'
                        src='nightsable-assets/images/headers/star5.svg'
                        alt=''
                      />
                      <img
                        src='nightsable-assets/images/headers/swa_star3.svg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          <img
            className='absolute top-0 left-0'
            src='nightsable-assets/images/headers/layer-blur.svg'
            alt=''
          />
          <img
            className='absolute right-0 top-24'
            src='nightsable-assets/images/headers/lines.svg'
            alt=''
          />
        </section>
        <section id="section-services" className='py-24 bg-gray-50'>
          <div className='container px-4 mx-auto'>
            <div className='text-center'>
              <h2 className='mx-auto mb-8 text-black text-7xl lg:text-8xl tracking-8xl md:max-w-md'>
                Services
              </h2>
              <div className='flex flex-wrap -m-4'>
                <div className='w-full p-4 md:w-1/2 lg:w-1/3'>
                  <div className='relative h-full px-10 pt-16 border border-gray-800 pb-52 bg-gradient-radial-dark border-opacity-30 rounded-5xl'>
                    <div className='relative z-10'>
                      <img
                        className='mx-auto h-[240px] mb-8'
                        src='nightsable-assets/images/cards/swa_web_building.svg'
                        alt=''
                      />
                      <h3 className='mb-6 text-3xl text-black tracking-3xl'>
                        Website Creation
                      </h3>
                      <p className='text-black'>
                        From logo design, UI/UX design, to react and tailwind css development. 
                        We turn your business ideas to a fully developed website.

                      </p>
                    </div>
                    <img
                      className='absolute bottom-0 right-0'
                      src='nightsable-assets/images/cards/card-blur.svg'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full p-4 md:w-1/2 lg:w-1/3'>
                  <div className='relative h-full px-10 pt-16 border border-gray-800 pb-52 bg-gradient-radial-dark border-opacity-30 rounded-5xl'>
                    <div className='relative z-10'>
                      <img
                        className='mx-auto h-[240px] mb-8'
                        src='nightsable-assets/images/cards/swa_web_hosting.svg'
                        alt=''
                      />
                      <h3 className='mb-6 text-3xl text-black tracking-3xl'>
                        Web Hosting
                      </h3>
                      <p className='text-black'>
                        Don't know how to get your website on the internet? Don't panick, we take care of that for you.
                        We provide service for domain registration and setting up domain to our hosting servers.  
                      </p>
                    </div>
                    <img
                      className='absolute bottom-0 right-0'
                      src='nightsable-assets/images/cards/card-blur.svg'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full p-4 md:w-1/2 lg:w-1/3'>
                  <div className='relative h-full px-10 pt-16 border border-gray-800 pb-52 bg-gradient-radial-dark border-opacity-30 rounded-5xl'>
                    <div className='relative z-10'>
                      <img
                        className='mx-auto h-[240px] mb-8'
                        src='nightsable-assets/images/cards/swa_web_maintenance.svg'
                        alt=''
                      />
                      <h3 className='mb-6 text-3xl text-black tracking-3xl'>
                        Web Maintenace
                      </h3>
                      <p className='text-black'>
                        Having issues with your website? We provide debugging services to elimate the issue.
                      </p>
                    </div>
                    <img
                      className='absolute bottom-0 right-0'
                      src='nightsable-assets/images/cards/card-blur.svg'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section-ourwork" className='relative py-24 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='mx-auto mb-8 text-center md:max-w-xl'>
              <h2 className='text-white text-7xl lg:text-8xl tracking-tighter-xl'>
                Our Work
              </h2>
            </div>
            <div className='w-full h-full'>
              <Slider centerMode={true} {...settings}>
                <div>
                  <img src={DecentralCard1} height="1000px" width="1000px" />
                </div>
                <div>
                  <img src={DecentralCard2} height="1000px" width="1000px" />
                </div>
                <div>
                <img src={MomentumVendingCard1} height="1000px" width="1000px" />
                </div>
                <div>
                  <img src={MomentumVendingCard2} height="1000px" width="1000px" />
                </div>
                <div>
                  <img src={StylusCard} height="1000px" width="1000px" />
                </div>
                <div>
                  <img src={YummyFoodCard} height="1000px" width="1000px" />
                </div>

              </Slider>
              
            </div>
          </div>
        </section>
        <section id="section-aboutus" className='overflow-hidden bg-gray-50'>
          <div className='bg-black py-14 rounded-b-7xl' />
          <div className='py-24'>
            <div className='container px-4 mx-auto'>
              <div className='md:flex lg:grid grid-cols-3 mb-2'>
                <div className='flex flex-col w-full px-8'>
                  
                    
                    <div className='flex w-full justify-center'>
                      <img className='h-[63.47px] w-[100px] mb-7' src='nightsable-assets/logos/swa_light_gradient.svg' alt=''/>
                    </div>

                    <div className='flex w-full justify-center'>
                      <p className='flex w-[300px] px-8 font-medium text-gray-400'>
                        We offer full-scale web design, development, and hosting services to 
                        help enhance your online presence and pave way for new growth opportunities!
                      </p>
                    </div>

                </div>

                <div className='w-full p-8'>
                  <h3 className='flex justify-center mb-6 text-lg font-medium text-black'>Contact Us</h3>
                  <ul>
                    <li className='mb-2.5'>
                      <div
                        className='flex justify-center text-lg font-medium text-gray-400 transition duration-300 hover:text-black hover:cursor-pointer'
                      >
                        {JSON.parse(window.localStorage.getItem('country')) === 'Liberia' ? '+231 881-112-3833' : '+1 267-298-6983'}
                      </div>
                    </li>
                    <li className='mb-2.5'>
                      <div
                        className='flex justify-center text-lg font-medium text-gray-400 transition duration-300 hover:text-black hover:cursor-pointer'
                      >
                        {JSON.parse(window.localStorage.getItem('country')) === 'Liberia' ? 'Monrovia, Liberia' : 'Boston, Massachusetts'}
                      </div>
                    </li>
                    
                    <li className='mb-2.5'>
                      <div
                        className='flex justify-center text-lg font-medium text-gray-400 transition duration-300 hover:text-black hover:cursor-pointer'
                      >
                        saiewebagency@gmail.com
                      </div>
                    </li>
                  </ul>
                </div>
                
                <div className='w-full p-8'>
                  <div className='grid grid-row-3 -m-2'>
                    
                  <div className='w-full p-2'>
                      <a
                        className='block px-8 py-5 bg-white rounded-full'
                        target="_blank" href='https://www.facebook.com/profile.php?id=61553611337652'
                      >
                        <div className='flex flex-wrap justify-center space-x-3 p-2 -m-2'>
                          <div className='w-auto'>
                            <img
                              src='/nightsable-assets/images/footers/fb-logo.svg'
                              alt=''
                              className='w-[26px]'
                            />
                          </div>
                          <div className=''>
                            <p className='text-black'>
                              Saie Web Agency
                            </p>

                          </div>
                        </div>
                      </a>
                    </div>

                    <div className='w-full p-2'>
                      <a
                        className='block px-8 py-5 bg-white rounded-full'
                        target="_blank" href='https://www.linkedin.com/company/saieagency/'
                      >
                        <div className='flex flex-wrap justify-center space-x-3 p-2 -m-2'>
                          <div className='w-auto'>
                            <img
                              className='w-[24px]'
                              src='nightsable-assets/images/footers/linkedIn_icon.svg'
                              alt=''
                            />
                          </div>
                          <div className=''>
                            <p className='text-black'>
                              Saie Web Agency
                            </p>

                          </div>
                        </div>
                      </a>
                    </div>

                    <div className='w-full p-2'>
                      <a
                        className='block px-8 py-5 bg-white rounded-full'
                        target="_blank" href='https://www.instagram.com/saieagency/'
                      >
                        <div className='flex flex-wrap justify-center space-x-3 p-2 -m-2'>
                          <div className='w-auto'>
                            <img
                              className='w-[24px]'
                              src='nightsable-assets/images/footers/instagram.svg'
                              alt=''
                            />
                          </div>
                          <div className=''>
                            <p className='text-black'>
                              @saieagency
                            </p>

                          </div>
                        </div>
                      </a>
                    </div>

                    <div className='w-full p-2'>
                      <a
                        className='block px-8 py-5 bg-white rounded-full'
                        target="_blank" href='https://www.youtube.com/@saieagency'
                      >
                        <div className='flex flex-wrap self-center space-x-3 justify-center p-2 -m-2'>
                          <div className='w-auto h-full'>
                            <img
                              className='h-[20px] w-[89.65px]' src='nightsable-assets/images/footers/youtube_logo.svg'
                              alt=''
                            />
                          </div>
                          <div className='h-full'>
                            <p className='text-black'>
                              @saieagency
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
              <div className='flex flex-wrap justify-center'>
                <div className='w-auto p-2'>
                  <p className='inline-block text-sm font-medium text-black text-opacity-60'>
                    © 2023 Saie Web Agency
                  </p>
                </div>
              </div>
            </div>
          </div>
          
        </section>
      </div>
  );
}